.section_highlightslider {
  margin: 60px 0px;
}
.section_banner{
  /* padding: 15px; */
}
.section_banner .carousel-inner .highlight-img{
  height: 400px;
}
.section_banner .carousel-control-prev-icon {
  rotate: 180deg;
}
.section_banner .carousel-control-prev-icon, .section_banner .carousel-control-next-icon {
   position: relative; 
  background-color: #fff; 
   border-radius: 50%;
  width: 40px;
  height: 40px; 
}
/* 
.section_banner .carousel-control-prev-icon,.section_banner
.carousel-control-next-icon {
  position: relative;
  background-color: #ffffffc3 !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #0081C3;
  box-shadow: 1px 1px 4px 0px #00000040;
}
.section_banner .carousel-control-prev {
  right: -15px !important;
}
.section_banner .carousel-control-prev::before,.section_banner
.carousel-control-next::before {
  font-size: 30px !important;
  position: relative;
  top: 6px;
}

.section_banner .carousel-control-prev {
  left: -15px !important;
  z-index: 9 !important;
}
.section_banner .carousel-control-next::before {
  background: url(../../../assets/right.png);
  background-repeat: no-repeat;
  color: transparent;
}
.section_banner .carousel-control-prev::before {
  background: url(../../../assets/left.png);
  background-repeat: no-repeat;
  color: transparent;
}
.section_banner .slick-track {
  display: flex;
  gap: 20px;
} */

@media screen and (max-width:768px) {
.section_banner .slick-track {
  gap: 10px;
}
}

.section_highlightslider .carousel-caption {
    position: absolute;
    right: 0%;
    bottom: 1.25rem;
    left: 1%;
    color: #fff;
    text-align: left;
    max-width: 635px;
    top: 20%;
    padding: 0px 20px;
  }
  .section_highlightslider .carousel-caption h4 {
    border-left: 5px solid yellow;
    padding: 0px 0px 0px 15px;
    color: #FBFF4D;
    font-size: 25px;
    font-weight: 700;
  }
  .section_highlightslider .carousel-caption h4 span{
color: #fff;
    font-size: 20px;
    font-weight: 600;
  }
  .section_highlightslider .carousel-caption .info {
    padding: 0px 20px 0px 20px;
  }
  .section_highlightslider .carousel-caption .info h5{
  color: #0081C3;
  font-size: 18px;
  }
  
  .section_highlightslider .carousel-inner .carousel-item img{
    max-height: 450px;
    object-fit: cover;
  }
  
  @media screen and (max-width: 991px) {
    .section_highlightslider .carousel-item {
      min-height: 500px;
    }
    .section_highlightslider .carousel-item img {
      min-height: 500px;
      object-fit: cover;
      max-height: 500px;
    }
    .section_banner .carousel-inner .highlight-img{
      height: 100%;
    }
  }
  
